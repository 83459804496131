// src/components/Docs/DocsConfigManagement.js
import React, { useState, useEffect } from 'react';
import { db } from '../../firebase';
import { doc, getDoc, updateDoc } from 'firebase/firestore';
import { Loader2, ScrollText, Settings } from 'lucide-react';
import Card from '../Card';
import { useAuth } from '../../contexts/AuthContext';
import DocsPrompts from './DocsPrompts';
import LabelGenerationPrompt from './LabelGenerationPrompt';
import CategoryPrompts from './CategoryPrompts';


const DocsConfigManagement = () => {
  const [configData, setConfigData] = useState({
    Model: '',
    temperature: 0.1,
    topK: 10,
    topP: 0.95,
    batchSize: 2
  });
  const [availableModels, setAvailableModels] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [saveStatus, setSaveStatus] = useState({ type: '', message: '' });
  const [activeTab, setActiveTab] = useState('config');
  const { currentUser } = useAuth();

  useEffect(() => {
    const fetchConfig = async () => {
      try {
        setIsLoading(true);
        
        // Fetch Models document
        const modelsDoc = await getDoc(doc(db, 'DocsConfig', 'Models'));
        if (modelsDoc.exists()) {
          setAvailableModels(modelsDoc.data().models || []);
        }

        // Fetch Config document
        const configDoc = await getDoc(doc(db, 'DocsConfig', 'Config'));
        if (configDoc.exists()) {
          const configDataFromDb = configDoc.data();
          
          setConfigData({
            Model: configDataFromDb.Model || '',
            temperature: configDataFromDb.temperature ?? 0.1,
            topK: configDataFromDb.topK ?? 10,
            topP: configDataFromDb.topP ?? 0.95,
            batchSize: configDataFromDb.batchSize ?? 2
          });
        }

      } catch (err) {
        console.error('Error fetching configuration:', err);
        setError(err.message);
      } finally {
        setIsLoading(false);
      }
    };

    fetchConfig();
  }, []);

  const handleModelChange = async (newModel) => {
    try {
      setSaveStatus({ type: 'loading', message: 'Updating model...' });
      
      await updateDoc(doc(db, 'DocsConfig', 'Config'), {
        Model: newModel
      });

      setConfigData(prev => ({
        ...prev,
        Model: newModel
      }));

      setSaveStatus({ type: 'success', message: 'Model updated successfully!' });
    } catch (err) {
      console.error('Error updating model:', err);
      setSaveStatus({ type: 'error', message: 'Failed to update model' });
    } finally {
      setTimeout(() => setSaveStatus({ type: '', message: '' }), 3000);
    }
  };

  const handleParameterChange = async (parameter, value) => {
    try {
      setSaveStatus({ type: 'loading', message: `Updating ${parameter}...` });
      
      await updateDoc(doc(db, 'DocsConfig', 'Config'), {
        [parameter]: Number(value)
      });

      setConfigData(prev => ({
        ...prev,
        [parameter]: Number(value)
      }));

      setSaveStatus({ type: 'success', message: `${parameter} updated successfully!` });
    } catch (err) {
      console.error(`Error updating ${parameter}:`, err);
      setSaveStatus({ type: 'error', message: `Failed to update ${parameter}` });
    } finally {
      setTimeout(() => setSaveStatus({ type: '', message: '' }), 3000);
    }
  };

  if (isLoading) {
    return (
      <div className="flex items-center justify-center p-8">
        <Loader2 className="h-8 w-8 animate-spin text-gray-500" />
      </div>
    );
  }

  if (error) {
    return (
      <div className="p-4 text-red-500">
        Error loading configuration: {error}
      </div>
    );
  }

// Updated return statement for DocsConfigManagement.js
return (
  <div className="w-full">
    <div className="flex justify-between items-center mb-4">
      {saveStatus.message && (
        <div className={`px-4 py-2 rounded ${
          saveStatus.type === 'success' ? 'bg-green-100 text-green-700' :
          saveStatus.type === 'error' ? 'bg-red-100 text-red-700' :
          'bg-blue-100 text-blue-700'
        }`}>
          {saveStatus.message}
        </div>
      )}
    </div>
    
    <div className="flex gap-4">
      {/* Vertical Tabs */}
      <div className="w-52 flex-shrink-0">
        <div className="bg-white rounded-lg border border-gray-200 overflow-hidden">
          <button
            onClick={() => setActiveTab('config')}
            className={`w-full flex items-center gap-2 px-3 py-3 text-left transition-colors ${
              activeTab === 'config' 
                ? 'bg-primary/5 border-r-4 border-primary'
                : 'hover:bg-gray-50'
            }`}
          >
            <Settings className={`h-4 w-4 ${activeTab === 'config' ? 'text-primary' : 'text-gray-500'}`} />
            <div>
              <div className={`text-sm font-medium ${activeTab === 'config' ? 'text-primary' : 'text-gray-700'}`}>
                Model Config
              </div>
              <div className="text-[11px] text-gray-500">
                Configure model settings
              </div>
            </div>
          </button>
          
          <button
            onClick={() => setActiveTab('prompt')}
            className={`w-full flex items-center gap-2 px-3 py-3 text-left transition-colors ${
              activeTab === 'prompt' 
                ? 'bg-primary/5 border-r-4 border-primary'
                : 'hover:bg-gray-50'
            }`}
          >
            <ScrollText className={`h-4 w-4 ${activeTab === 'prompt' ? 'text-primary' : 'text-gray-500'}`} />
            <div>
              <div className={`text-sm font-medium ${activeTab === 'prompt' ? 'text-primary' : 'text-gray-700'}`}>
                Label Documents Prompt
              </div>
              <div className="text-[11px] text-gray-500">
                Configure documents labeling prompt
              </div>
            </div>
          </button>

          <button
            onClick={() => setActiveTab('labelgen')}
            className={`w-full flex items-center gap-2 px-3 py-3 text-left transition-colors ${
              activeTab === 'labelgen' 
                ? 'bg-primary/5 border-r-4 border-primary'
                : 'hover:bg-gray-50'
            }`}
          >
            <ScrollText className={`h-4 w-4 ${activeTab === 'labelgen' ? 'text-primary' : 'text-gray-500'}`} />
            <div>
              <div className={`text-sm font-medium ${activeTab === 'labelgen' ? 'text-primary' : 'text-gray-700'}`}>
                Label Generation Prompt
              </div>
              <div className="text-[11px] text-gray-500">
                Configure label generation prompt
              </div>
            </div>
          </button>

          <button
            onClick={() => setActiveTab('categoryPrompt')}
            className={`w-full flex items-center gap-2 px-3 py-3 text-left transition-colors ${
              activeTab === 'categoryPrompt' 
                ? 'bg-primary/5 border-r-4 border-primary'
                : 'hover:bg-gray-50'
            }`}
          >
            <ScrollText className={`h-4 w-4 ${activeTab === 'categoryPrompt' ? 'text-primary' : 'text-gray-500'}`} />
            <div>
              <div className={`text-sm font-medium ${activeTab === 'categoryPrompt' ? 'text-primary' : 'text-gray-700'}`}>
                Category Prompt
              </div>
              <div className="text-[11px] text-gray-500">
                Configure document categorization prompt
              </div>
            </div>
          </button>
        </div>
      </div>

      {/* Content Area */}
      <div className="flex-1">
        <Card className="h-full">
          <div className="h-full flex flex-col">
            {activeTab === 'config' && (
              <div className="p-4">
                <div className="mb-6">
                  <h3 className="text-lg font-medium mb-4">Model Configuration</h3>
                  <div className="space-y-4">
                    <div>
                      <label className="block text-sm font-medium text-gray-700 mb-2">
                        Model
                      </label>
                      <select
                        value={configData.Model}
                        onChange={(e) => handleModelChange(e.target.value)}
                        className="w-full px-3 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                      >
                        <option value="">Select a model</option>
                        {availableModels.map((model) => (
                          <option key={model} value={model}>
                            {model}
                          </option>
                        ))}
                      </select>
                    </div>

                    <div>
                      <label className="block text-sm font-medium text-gray-700 mb-2">
                        Temperature (0.0 - 1.0)
                      </label>
                      <p className="text-sm text-gray-500 mb-2">
                        Controls randomness in label selection. Higher values (e.g., 0.8) make labeling more creative, lower values (e.g., 0.1) make it more focused and deterministic.
                      </p>
                      <input
                        type="number"
                        step="0.1"
                        min="0"
                        max="1"
                        value={configData.temperature}
                        onChange={(e) => handleParameterChange('temperature', e.target.value)}
                        className="w-full px-3 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                      />
                    </div>

                    <div>
                      <label className="block text-sm font-medium text-gray-700 mb-2">
                        Top K
                      </label>
                      <p className="text-sm text-gray-500 mb-2">
                        Limits the number of labels considered at each step. Lower values (e.g., 10) ensure more focused and reliable labeling, while higher values allow more diverse options.
                      </p>
                      <input
                        type="number"
                        min="1"
                        max="40"
                        value={configData.topK}
                        onChange={(e) => handleParameterChange('topK', e.target.value)}
                        className="w-full px-3 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                      />
                    </div>

                    <div>
                      <label className="block text-sm font-medium text-gray-700 mb-2">
                        Top P (0.0 - 1.0)
                      </label>
                      <p className="text-sm text-gray-500 mb-2">
                        Controls diversity of label selection. Higher values include more varied but potentially less relevant labels, lower values stick to more obvious choices.
                      </p>
                      <input
                        type="number"
                        step="0.05"
                        min="0"
                        max="1"
                        value={configData.topP}
                        onChange={(e) => handleParameterChange('topP', e.target.value)}
                        className="w-full px-3 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                      />
                    </div>
                    <div>
                      <label className="block text-sm font-medium text-gray-700 mb-2">
                        Batch Size
                      </label>
                      <p className="text-sm text-gray-500 mb-2">
                        Number of documents to process in each batch. Lower values provide more granular progress updates but may be slower overall.
                      </p>
                      <input
                        type="number"
                        min="1"
                        max="20"
                        value={configData.batchSize}
                        onChange={(e) => handleParameterChange('batchSize', e.target.value)}
                        className="w-full px-3 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                      />
                    </div>
                  </div>
                </div>
              </div>
            )}
            {activeTab === 'prompt' && (
              <DocsPrompts />
            )}
            {activeTab === 'labelgen' && (
              <LabelGenerationPrompt />
            )}
            {activeTab === 'categoryPrompt' && (
              <CategoryPrompts />
            )}
          </div>
        </Card>
      </div>
    </div>
  </div>
);
};

export default DocsConfigManagement;