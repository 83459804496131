// src/components/Docs/VersionList.js
import React, { useState, useEffect, useCallback } from 'react';
import { History, Eye, Check, ArrowRight, AlertCircle, ArrowLeft, Tag, FolderTree } from 'lucide-react';
import { format } from 'date-fns';

const VersionList = ({ 
  versions, 
  currentVersion,
  onVersionSelect,
  onVersionApply,
  isLoading,
  type = 'labels'
}) => {
  const [showVersionModal, setShowVersionModal] = useState(false);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [versionToApply, setVersionToApply] = useState(null);
  const [previewVersion, setPreviewVersion] = useState(null);

  useEffect(() => {
    setPreviewVersion(null);
  }, [currentVersion]);

  useEffect(() => {
    const handleEsc = (event) => {
      if (event.key === 'Escape') {
        setShowVersionModal(false);
        setShowConfirmModal(false);
      }
    };

    window.addEventListener('keydown', handleEsc);
    return () => window.removeEventListener('keydown', handleEsc);
  }, []);

  const formatDate = (timestamp) => {
    if (!timestamp) return 'Unknown date';
    const date = timestamp.toDate ? timestamp.toDate() : new Date(timestamp);
    return format(date, 'MMM d, yyyy HH:mm');
  };

  const handleVersionSelect = (version) => {
    setPreviewVersion(version);
    onVersionSelect(version);
    setShowVersionModal(false);
  };

  const handleApplyClick = (version) => {
    setVersionToApply(version);
    setShowConfirmModal(true);
  };

  const handleConfirmApply = () => {
    if (versionToApply) {
      onVersionApply(versionToApply);
      setPreviewVersion(null);
      setVersionToApply(null);
      setShowConfirmModal(false);
      setShowVersionModal(false);
    }
  };

  const handleCloseModal = () => {
    setShowVersionModal(false);
  };

  const handleClickOutside = useCallback((e) => {
    if (e.target === e.currentTarget) {
      setShowVersionModal(false);
      setShowConfirmModal(false);
    }
  }, []);

  const getVersionStatus = (version) => {
    if (version.id === currentVersion) return 'current';
    const currentIdx = versions.findIndex(v => v.id === currentVersion);
    const versionIdx = versions.findIndex(v => v.id === version.id);
    return versionIdx < currentIdx ? 'newer' : 'older';
  };

  // Helper function to render the dynamic items list (labels or categories)
  const getItemsText = (version) => {
    const items = type === 'labels' ? version.labels : version.categories;
    const Icon = type === 'labels' ? Tag : FolderTree;
    return (
      <div className="flex flex-wrap gap-1 mt-1">
        {items?.map((item, index) => (
          <span
            key={index}
            className="inline-flex items-center px-2 py-0.5 rounded-full text-xs font-medium bg-gray-100 text-gray-700"
          >
            <Icon className="h-3 w-3 mr-1" />
            {item}
          </span>
        ))}
        {(!items || items.length === 0) && (
          <span className="text-xs text-gray-500 italic">No {type} defined</span>
        )}
      </div>
    );
  };

  const ConfirmationModal = () => {
    const currentVersionData = versions.find(v => v.id === currentVersion);
    
    return (
      <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50" onClick={handleClickOutside}>
        <div className="bg-white rounded-lg p-6 max-w-md w-full">
          <h2 className="text-xl font-semibold mb-4">Confirm Version Change</h2>
          
          <div className="space-y-4">
            <div className="p-3 bg-gray-50 rounded-md">
              <div className="text-sm text-gray-600">From:</div>
              <div className="font-medium">Version {currentVersionData?.versionNumber}</div>
              <div className="text-xs text-gray-600">
                {type.charAt(0).toUpperCase() + type.slice(1)}: {(type === 'labels' ? currentVersionData?.labels?.length : currentVersionData?.categories?.length) || 0}
                <div className="mt-1 flex flex-wrap gap-1">
                  {(type === 'labels' ? currentVersionData?.labels : currentVersionData?.categories)?.map((item, index) => (
                    <span key={index} className="inline-flex items-center px-2 py-0.5 rounded text-xs bg-gray-200 text-gray-700">
                      {item}
                    </span>
                  ))}
                </div>
              </div>
              <div className="text-xs text-gray-600 mt-2">
                Created by{' '}
                <span className="bg-gray-100 px-1.5 py-0.5 rounded">{currentVersionData?.createdBy}</span>
                {' • '}
                <span className="text-gray-500">{formatDate(currentVersionData?.createdAt)}</span>
              </div>
            </div>

            <div className="flex items-center justify-center">
              <ArrowRight className="h-6 w-6 text-gray-400" />
            </div>

            <div className="p-3 bg-blue-50 rounded-md">
              <div className="text-sm text-gray-600">To:</div>
              <div className="font-medium">Version {versionToApply?.versionNumber}</div>
              <div className="text-xs text-gray-600">
                {type.charAt(0).toUpperCase() + type.slice(1)}: {(type === 'labels' ? versionToApply?.labels?.length : versionToApply?.categories?.length) || 0}
                <div className="mt-1 flex flex-wrap gap-1">
                  {(type === 'labels' ? versionToApply?.labels : versionToApply?.categories)?.map((item, index) => (
                    <span key={index} className="inline-flex items-center px-2 py-0.5 rounded text-xs bg-blue-200 text-blue-700">
                      {item}
                    </span>
                  ))}
                </div>
              </div>
              <div className="text-xs text-gray-600 mt-2">
                Created by{' '}
                <span className="bg-gray-100 px-1.5 py-0.5 rounded">{versionToApply?.createdBy}</span>
                {' • '}
                <span className="text-gray-500">{formatDate(versionToApply?.createdAt)}</span>
              </div>
            </div>

            <div className="text-sm text-gray-600 bg-yellow-50 p-3 rounded-md flex items-start gap-2">
              <AlertCircle className="h-4 w-4 mt-0.5" />
              <div>
                This will change the active version. All document uploads will use these {type}.
              </div>
            </div>
          </div>

          <div className="flex justify-end gap-3 mt-6">
            <button
              onClick={() => setShowConfirmModal(false)}
              className="px-4 py-2 text-sm text-gray-600 hover:bg-gray-100 rounded-md transition-colors"
            >
              Cancel
            </button>
            <button
              onClick={handleConfirmApply}
              className="px-4 py-2 text-sm bg-blue-500 text-white rounded-md hover:bg-blue-600 transition-colors"
            >
              Apply Version {versionToApply?.versionNumber}
            </button>
          </div>
        </div>
      </div>
    );
  };

const VersionModal = () => (
  <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50 p-4" onClick={handleClickOutside}>
    <div className="bg-white rounded-lg flex flex-col w-full max-w-2xl" style={{ maxHeight: 'calc(100vh - 32px)' }} onClick={e => e.stopPropagation()}>
      <div className="flex-none px-6 py-4 border-b">
        <div className="flex justify-between items-center">
          <h2 className="text-xl font-semibold">{type.charAt(0).toUpperCase() + type.slice(1)} Version History</h2>
          <button 
            onClick={handleCloseModal}
            className="text-gray-500 hover:text-gray-700 text-2xl w-8 h-8 flex items-center justify-center"
          >
            ×
          </button>
        </div>

        <div className="mt-4">
          <div className="flex items-center gap-2 text-sm text-gray-600">
            <AlertCircle className="h-4 w-4" />
            <span>Select a version to preview its {type}</span>
          </div>
        </div>
      </div>

      <div className="min-h-0 flex-1 overflow-y-auto" onClick={e => e.stopPropagation()}>
        <div className="p-6 space-y-2">
          {versions.map((version) => {
            const status = getVersionStatus(version);
            const isActive = version.id === currentVersion;
            const isPreviewing = version.id === previewVersion?.id;

            return (
              <div
                key={version.id}
                className={`p-4 rounded-lg border transition-all ${
                  isActive 
                    ? 'bg-green-50 border-green-200'
                    : isPreviewing
                      ? 'bg-blue-50 border-blue-200'
                      : 'border-gray-200 hover:border-gray-300'
                } ${!isActive && !isPreviewing ? 'hover:shadow-sm' : ''}`}
              >
                <div className="flex justify-between items-start">
                  <div className="flex-1">
                    <div className="flex items-center gap-2">
                      <span className="font-medium">Version {version.versionNumber}</span>
                      {status === 'newer' && (
                        <span className="text-xs bg-purple-100 text-purple-800 px-2 py-1 rounded">
                          Newer Version
                        </span>
                      )}
                      {isActive && (
                        <span className="text-xs bg-green-100 text-green-800 px-2 py-1 rounded flex items-center gap-1">
                          <Check className="h-3 w-3" />
                          Active
                        </span>
                      )}
                      {isPreviewing && !isActive && (
                        <span className="text-xs bg-blue-100 text-blue-800 px-2 py-1 rounded flex items-center gap-1">
                          <Eye className="h-3 w-3" />
                          Previewing
                        </span>
                      )}
                    </div>

                    <div className="mt-2">
                      <div className="text-sm text-gray-600">
                        {type.charAt(0).toUpperCase() + type.slice(1)} ({(type === 'labels' ? version.labels : version.categories)?.length || 0})
                      </div>
                      {getItemsText(version)}
                    </div>

                    <div className="space-y-1 mt-2">
                      <div className="text-xs text-gray-600">
                        Created by{' '}
                        <span className="bg-gray-100 px-1.5 py-0.5 rounded">{version.createdBy}</span>
                        {' • '}
                        <span className="text-gray-500">{formatDate(version.createdAt)}</span>
                      </div>
                      {version.lastAppliedAt && (
                        <div className="text-xs text-gray-600">
                          Last applied{' '}
                          <span className="bg-gray-100 px-1.5 py-0.5 rounded">{version.lastAppliedBy}</span>
                          {' • '}
                          <span className="text-gray-500">{formatDate(version.lastAppliedAt)}</span>
                        </div>
                      )}
                    </div>
                  </div>

                  <div className="flex items-center gap-2">
                    <button
                      onClick={(e) => {
                        e.stopPropagation();
                        handleVersionSelect(version);
                      }}
                      className="px-3 py-1.5 text-sm text-gray-600 hover:text-gray-900 hover:bg-gray-100 rounded-md transition-colors flex items-center gap-1"
                    >
                      <Eye className="h-4 w-4" />
                      Preview
                    </button>
                    {!isActive && (
                      <button
                        onClick={(e) => {
                          e.stopPropagation();
                          handleApplyClick(version);
                        }}
                        className="px-3 py-1.5 text-sm bg-gray-100 text-gray-700 hover:bg-gray-200 rounded-md transition-colors flex items-center gap-1"
                      >
                        <ArrowRight className="h-4 w-4" />
                        Apply
                      </button>
                    )}
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  </div>
);

  if (isLoading) {
    return <div className="text-sm text-gray-500">Loading versions...</div>;
  }

  if (!versions || versions.length === 0) {
    return <div className="text-sm text-gray-500">No versions available</div>;
  }

  const currentVersionData = versions.find(v => v.id === currentVersion);
  const isPreviewMode = previewVersion && previewVersion.id !== currentVersion;
  
  return (
    <>
      <div className={`rounded-lg border ${
        isPreviewMode 
          ? 'bg-blue-50/70 border-blue-200' 
          : 'bg-white border-gray-200'
      }`}>
        {!isPreviewMode ? (
          <div className="px-4 py-2.5 flex items-center justify-between">
            <div className="flex items-center gap-4">
              <div className="flex items-center gap-2 bg-green-50 text-green-700 px-2.5 py-1 rounded-md">
                <Check className="h-4 w-4 text-green-600" />
                <span>Version {currentVersionData?.versionNumber}</span>
              </div>
              <div className="flex flex-col text-xs text-gray-600">
                <div>
                  Created by{' '}
                  <span className="bg-gray-100 px-1.5 py-0.5 rounded">{currentVersionData?.createdBy}</span>
                  {' • '}
                  <span className="text-gray-500">{formatDate(currentVersionData?.createdAt)}</span>
                </div>
                {currentVersionData?.lastAppliedAt && (
                  <div>
                    Last applied{' '}
                    <span className="bg-gray-100 px-1.5 py-0.5 rounded">{currentVersionData?.lastAppliedBy}</span>
                    {' • '}
                    <span className="text-gray-500">{formatDate(currentVersionData?.lastAppliedAt)}</span>
                  </div>
                )}
              </div>
            </div>
            
            <button
              onClick={() => setShowVersionModal(true)}
              className="flex items-center gap-1.5 px-3 py-1.5 text-sm text-gray-700 bg-gray-50 hover:bg-gray-100 rounded-md transition-all"
            >
              <History className="h-4 w-4" />
              <span>Versions</span>
            </button>
          </div>
        ) : (
          <>
            <div className="p-4">
              <div className="flex items-center justify-between">
                <div className="font-medium text-blue-700 flex items-center gap-2">
                  <Eye className="h-4 w-4 text-blue-600" />
                  <span>Previewing Version {previewVersion.versionNumber}</span>
                </div>
                
                <button
                  onClick={() => setShowVersionModal(true)}
                  className="flex items-center gap-1.5 px-3 py-1.5 text-sm text-gray-700 bg-white border border-gray-200 hover:bg-gray-50 rounded-md transition-all shadow-sm hover:shadow"
                >
                  <History className="h-4 w-4" />
                  <span>Versions</span>
                </button>
              </div>

              <div className="text-xs text-gray-600 mt-1">
                Created by{' '}
                <span className="bg-gray-100 px-1.5 py-0.5 rounded">{previewVersion?.createdBy}</span>
                {' on '}
                <span className="text-gray-500">{formatDate(previewVersion?.createdAt)}</span>
              </div>

              <div className="mt-2">
                <div className="text-sm text-gray-600">
                  {type.charAt(0).toUpperCase() + type.slice(1)} ({(type === 'labels' ? previewVersion.labels : previewVersion.categories)?.length || 0})
                </div>
                <div className="flex flex-wrap gap-1 mt-1">
                  {(type === 'labels' ? previewVersion.labels : previewVersion.categories)?.map((item, index) => {
                    const Icon = type === 'labels' ? Tag : FolderTree;
                    return (
                      <span
                        key={index}
                        className="inline-flex items-center px-2 py-0.5 rounded-full text-xs font-medium bg-blue-100 text-blue-700"
                      >
                        <Icon className="h-3 w-3 mr-1" />
                        {item}
                      </span>
                    );
                  })}
                  {(!(type === 'labels' ? previewVersion.labels : previewVersion.categories) || (type === 'labels' ? previewVersion.labels : previewVersion.categories).length === 0) && (
                    <span className="text-xs text-gray-500 italic">No {type}</span>
                  )}
                </div>
              </div>
            </div>

            <div className="border-t border-blue-200 bg-blue-50/50 p-3 flex items-center justify-between">
              <button 
                onClick={() => handleVersionSelect(currentVersionData)}
                className="text-sm px-3.5 py-2 bg-white border border-gray-200 hover:bg-gray-50 rounded-md flex items-center gap-2 group transition-all shadow-sm hover:shadow"
              >
                <ArrowLeft className="h-4 w-4 text-gray-600 group-hover:text-gray-700" />
                <span>Return to Active Version</span>
              </button>

              <button
                onClick={() => handleApplyClick(previewVersion)}
                className="px-4 py-2 text-sm bg-blue-600 text-white rounded-md hover:bg-blue-700 transition-colors flex items-center gap-2 shadow-sm"
              >
                <Check className="h-4 w-4" />
                <span>Apply Version {previewVersion.versionNumber}</span>
              </button>
            </div>
          </>
        )}
      </div>

      {showVersionModal && <VersionModal />}
      {showConfirmModal && <ConfirmationModal />}
    </>
  );
};

export default VersionList;