// src/components/CSVDownload.js
import React from 'react';
import { Download } from 'lucide-react';

const CSVDownload = ({ data, filename = 'download.csv', className = '' }) => {
  const downloadCSV = () => {
    if (!data || !data.length) return;

    try {
      // Get headers from first object
      const headers = Object.keys(data[0]);
      
      // Create CSV content
      const csvContent = [
        // Headers row
        headers.join(','),
        // Data rows
        ...data.map(item => 
          headers.map(header => {
            const value = item[header];
            // Handle different value types and ensure proper CSV formatting
            if (value === null || value === undefined) return '';
            if (value instanceof Date) return value.toLocaleString();
            if (typeof value === 'object') return JSON.stringify(value).replace(/"/g, '""');
            return `"${String(value).replace(/"/g, '""')}"`; // Escape quotes and wrap in quotes
          }).join(',')
        )
      ].join('\n');

      // Create blob and download
      const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
      const link = document.createElement('a');
      const url = URL.createObjectURL(blob);
      
      link.setAttribute('href', url);
      link.setAttribute('download', filename);
      link.style.visibility = 'hidden';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      URL.revokeObjectURL(url);
    } catch (error) {
      console.error('Error creating CSV:', error);
      alert('Error creating CSV file. Please try again.');
    }
  };

  return (
    <button
      onClick={downloadCSV}
      className={`inline-flex items-center px-2 py-1 text-xs font-medium text-gray-700 bg-white border border-gray-300 rounded hover:bg-gray-50 focus:outline-none focus:ring-1 focus:ring-blue-500 ${className}`}
    >
      <Download className="w-3 h-3 mr-1" />
      CSV
    </button>
  );
};

export default CSVDownload;