import React, { useState, useEffect } from 'react';
import Card from './Card';
import TextEditor from './TextEditor';
import { db } from '../firebase';
import { doc, getDoc, updateDoc, collection, query, orderBy, getDocs, addDoc, serverTimestamp } from 'firebase/firestore';
import { Loader2, ScrollText, FileText } from 'lucide-react';
import { convertMarkdownToHtml, convertHtmlToMarkdown } from './markdownConverter';
import PromptVersionList from './PromptVersionList';
import { useAuth } from '../contexts/AuthContext';

const PromptManagement = () => {
  const [configData, setConfigData] = useState({
    prompt: '',
    explanationPrompt: '',
    investigateEmptyPrompt: '',
    retryErrorPrompt: '',
    promptId: '',
    explanationPromptId: '',
    investigateEmptyPromptId: '',
    retryErrorPromptId: ''
  });
  const [previewData, setPreviewData] = useState({
    prompt: null,
    explanationPrompt: null,
    investigateEmptyPrompt: null,
    retryErrorPrompt: null
  });
  const [versions, setVersions] = useState({
    prompt: [],
    explanationPrompt: [],
    investigateEmptyPrompt: [],
    retryErrorPrompt: []
  });
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [saveStatus, setSaveStatus] = useState({ type: '', message: '' });
  const [activeTab, setActiveTab] = useState('general');
  const { currentUser } = useAuth();

  const formatText = (text) => {
    if (!text) return '';
    const lines = text.split('\n');
    return lines
      .map(line => {
        const trimmedLine = line.trim();
        if (!trimmedLine) return null;
        if (trimmedLine.startsWith('•') || trimmedLine.startsWith('-')) {
          return trimmedLine;
        }
        return trimmedLine;
      })
      .filter(Boolean)
      .join('\n');
  };

  const fetchVersions = async (type) => {
    try {
      const versionsRef = collection(db, 'DBI Configuration', type, 'versions');
      const versionsQuery = query(versionsRef, orderBy('versionNumber', 'desc'));
      const versionsSnapshot = await getDocs(versionsQuery);
      
      return versionsSnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
      }));
    } catch (error) {
      console.error(`Error fetching ${type} versions:`, error);
      return [];
    }
  };

  useEffect(() => {
    const fetchConfigData = async () => {
      try {
        setIsLoading(true);
        const configDoc = await getDoc(doc(db, 'DBI Configuration', 'Config'));
        
        if (configDoc.exists()) {
          const configData = configDoc.data();
          
          // Fetch all versions
          const [promptVersions, explanationVersions, investigateEmptyVersions, retryErrorVersions] = await Promise.all([
            fetchVersions('Prompts'),
            fetchVersions('Explanation Prompts'),
            fetchVersions('Investigate Empty Prompts'),
            fetchVersions('Retry Error Prompts')
          ]);

          setVersions({
            prompt: promptVersions,
            explanationPrompt: explanationVersions,
            investigateEmptyPrompt: investigateEmptyVersions,
            retryErrorPrompt: retryErrorVersions
          });

          // Get the current version for each type
          const currentPrompt = promptVersions.find(v => v.id === configData.Prompt)?.content || '';
          const currentExplanation = explanationVersions.find(v => v.id === configData.Explanation_Prompt)?.content || '';
          const currentInvestigateEmpty = investigateEmptyVersions.find(v => v.id === configData.Investigate_Empty_Prompt)?.content || '';
          const currentRetryError = retryErrorVersions.find(v => v.id === configData.Retry_Error_Prompt)?.content || '';


          const initialConfig = {
            prompt: convertMarkdownToHtml(currentPrompt),
            explanationPrompt: convertMarkdownToHtml(currentExplanation),
            investigateEmptyPrompt: convertMarkdownToHtml(currentInvestigateEmpty),
            retryErrorPrompt: convertMarkdownToHtml(currentRetryError),
            promptId: configData.Prompt,
            explanationPromptId: configData.Explanation_Prompt,
            investigateEmptyPromptId: configData.Investigate_Empty_Prompt,
            retryErrorPromptId: configData.Retry_Error_Prompt
          };

          setConfigData(initialConfig);
          setPreviewData({
            prompt: initialConfig.prompt,
            explanationPrompt: initialConfig.explanationPrompt,
            investigateEmptyPrompt: initialConfig.investigateEmptyPrompt,
            retryErrorPrompt: initialConfig.retryErrorPrompt
          });
        } else {
          setError('Configuration data not found');
        }
      } catch (err) {
        setError(err.message);
        console.error('Error fetching config data:', err);
      } finally {
        setIsLoading(false);
      }
    };

    fetchConfigData();
  }, []);

  const handleVersionSelect = (type, version) => {
    // Update preview content without changing the active version
    setPreviewData(prev => ({
      ...prev,
      [type]: convertMarkdownToHtml(version.content)
    }));
  };
  
  const handleVersionApply = async (type, version) => {
    try {
      setSaveStatus({ type: 'loading', message: 'Applying version...' });
      
      // Update Config document to point to the selected version
      const configRef = doc(db, 'DBI Configuration', 'Config');
      const updateField = type === 'prompt' 
        ? 'Prompt' 
        : type === 'explanationPrompt'
          ? 'Explanation_Prompt'
          : type === 'investigateEmptyPrompt'
            ? 'Investigate_Empty_Prompt'
            : 'Retry_Error_Prompt';
      
      // Update the version document to track who applied it and when
      const collectionName = type === 'prompt' 
        ? 'Prompts' 
        : type === 'explanationPrompt'
          ? 'Explanation Prompts'
          : type === 'investigateEmptyPrompt'
            ? 'Investigate Empty Prompts'
            : 'Retry Error Prompts';
      const versionRef = doc(db, 'DBI Configuration', collectionName, 'versions', version.id);
      await updateDoc(versionRef, {
        lastAppliedAt: serverTimestamp(),
        lastAppliedBy: currentUser?.email || 'unknown'
      });
  
      // Update the config to point to this version
      await updateDoc(configRef, {
        [updateField]: version.id
      });
  
      // Update both config and preview data
      const newContent = convertMarkdownToHtml(version.content);
      setConfigData(prev => ({
        ...prev,
        [type]: newContent,
        [`${type}Id`]: version.id
      }));
      setPreviewData(prev => ({
        ...prev,
        [type]: newContent
      }));
  
      // Update versions state to reflect the new applied timestamp
      setVersions(prev => ({
        ...prev,
        [type]: prev[type].map(v => 
          v.id === version.id 
            ? { 
                ...v, 
                lastAppliedAt: new Date(),
                lastAppliedBy: currentUser?.email || 'unknown'
              } 
            : v
        )
      }));
  
      setSaveStatus({ type: 'success', message: `Applied version ${version.versionNumber}` });
    } catch (error) {
      console.error('Error applying version:', error);
      setSaveStatus({ type: 'error', message: 'Failed to apply version' });
    } finally {
      setTimeout(() => setSaveStatus({ type: '', message: '' }), 3000);
    }
  };

  const handleSave = async (type, content) => {
    try {
      setSaveStatus({ type: 'loading', message: 'Saving new version...' });
      
      const markdownContent = convertHtmlToMarkdown(content);
      const collectionName = type === 'prompt' 
        ? 'Prompts' 
        : type === 'explanationPrompt' 
          ? 'Explanation Prompts'
          : type === 'investigateEmptyPrompt'
            ? 'Investigate Empty Prompts'
            : 'Retry Error Prompts';
  
      // Get the next version number
      const currentVersions = versions[type] || [];
      const nextVersionNumber = currentVersions.length > 0 ? 
        Math.max(...currentVersions.map(v => v.versionNumber || 0)) + 1 : 1;
  
      // Create new version document
      const versionsRef = collection(db, 'DBI Configuration', collectionName, 'versions');
      const newVersion = {
        content: markdownContent,
        createdAt: serverTimestamp(),
        createdBy: currentUser?.email || 'unknown',
        versionNumber: nextVersionNumber,
        lastAppliedAt: serverTimestamp(),
        lastAppliedBy: currentUser?.email || 'unknown'
      };
  
      const newVersionRef = await addDoc(versionsRef, newVersion);
  
      // Update Config document to point to new version
      const configRef = doc(db, 'DBI Configuration', 'Config');
      const updateField = type === 'prompt' 
        ? 'Prompt' 
        : type === 'explanationPrompt'
          ? 'Explanation_Prompt'
          : type === 'investigateEmptyPrompt'
            ? 'Investigate_Empty_Prompt'
            : 'Retry_Error_Prompt';
      await updateDoc(configRef, {
        [updateField]: newVersionRef.id
      });
  
      // Update local state with the new version
      const newVersionWithId = {
        id: newVersionRef.id,
        ...newVersion,
        createdAt: new Date(),
        lastAppliedAt: new Date()
      };
  
      setVersions(prev => ({
        ...prev,
        [type]: [newVersionWithId, ...(prev[type] || [])]
      }));
  
      // Update both config and preview data
      setConfigData(prev => ({
        ...prev,
        [type]: content,
        [`${type}Id`]: newVersionRef.id
      }));
      setPreviewData(prev => ({
        ...prev,
        [type]: content
      }));
  
      setSaveStatus({ type: 'success', message: 'Saved and activated new version!' });
    } catch (err) {
      console.error('Error saving:', err);
      setSaveStatus({ 
        type: 'error', 
        message: `Failed to save changes: ${err.message}` 
      });
    } finally {
      setTimeout(() => setSaveStatus({ type: '', message: '' }), 3000);
    }
  };

  const handleCancel = (type) => {
    // Reset preview to current active version
    const currentVersion = versions[type]?.find(v => v.id === configData[`${type}Id`]);
    if (currentVersion) {
      setPreviewData(prev => ({
        ...prev,
        [type]: convertMarkdownToHtml(currentVersion.content)
      }));
    }
    setSaveStatus({ type: '', message: '' });
  };

  if (isLoading) {
    return (
      <div className="flex items-center justify-center min-h-screen">
        <Loader2 className="h-8 w-8 animate-spin text-gray-500" />
      </div>
    );
  }

  if (error) {
    return (
      <div className="p-4 text-red-500">
        Error loading configuration: {error}
      </div>
    );
  }

  return (
    <div className="w-full">
      <div className="flex justify-between items-center mb-4">
        {saveStatus.message && (
          <div className={`px-4 py-2 rounded ${
            saveStatus.type === 'success' ? 'bg-green-100 text-green-700' :
            saveStatus.type === 'error' ? 'bg-red-100 text-red-700' :
            'bg-blue-100 text-blue-700'
          }`}>
            {saveStatus.message}
          </div>
        )}
      </div>
      
      <div className="flex gap-4">
        {/* Vertical Tabs */}
        <div className="w-52 flex-shrink-0">
          <div className="bg-white rounded-lg border border-gray-200 overflow-hidden">
            <button
              onClick={() => setActiveTab('general')}
              className={`w-full flex items-center gap-2 px-3 py-3 text-left transition-colors ${
                activeTab === 'general' 
                  ? 'bg-primary/5 border-r-4 border-primary'
                  : 'hover:bg-gray-50'
              }`}
            >
              <ScrollText className={`h-4 w-4 ${activeTab === 'general' ? 'text-primary' : 'text-gray-500'}`} />
              <div>
                <div className={`text-sm font-medium ${activeTab === 'general' ? 'text-primary' : 'text-gray-700'}`}>
                  General Prompt
                </div>
                <div className="text-[11px] text-gray-500">
                  Main system prompt for queries
                </div>
              </div>
            </button>
            
            <button
              onClick={() => setActiveTab('explanation')}
              className={`w-full flex items-center gap-2 px-3 py-3 text-left transition-colors ${
                activeTab === 'explanation' 
                  ? 'bg-primary/5 border-r-4 border-primary'
                  : 'hover:bg-gray-50'
              }`}
            >
              <FileText className={`h-4 w-4 ${activeTab === 'explanation' ? 'text-primary' : 'text-gray-500'}`} />
              <div>
                <div className={`text-sm font-medium ${activeTab === 'explanation' ? 'text-primary' : 'text-gray-700'}`}>
                  Explanation Prompt
                </div>
                <div className="text-[11px] text-gray-500">
                  Prompt for generating explanations
                </div>
              </div>
            </button>
  
            <button
              onClick={() => setActiveTab('investigateEmpty')}
              className={`w-full flex items-center gap-2 px-3 py-3 text-left transition-colors ${
                activeTab === 'investigateEmpty' 
                  ? 'bg-primary/5 border-r-4 border-primary'
                  : 'hover:bg-gray-50'
              }`}
            >
              <FileText className={`h-4 w-4 ${activeTab === 'investigateEmpty' ? 'text-primary' : 'text-gray-500'}`} />
              <div>
                <div className={`text-sm font-medium ${activeTab === 'investigateEmpty' ? 'text-primary' : 'text-gray-700'}`}>
                  Investigate Empty
                </div>
                <div className="text-[11px] text-gray-500">
                  Prompt for empty result investigation
                </div>
              </div>
            </button>
  
            <button
              onClick={() => setActiveTab('retryError')}
              className={`w-full flex items-center gap-2 px-3 py-3 text-left transition-colors ${
                activeTab === 'retryError' 
                  ? 'bg-primary/5 border-r-4 border-primary'
                  : 'hover:bg-gray-50'
              }`}
            >
              <FileText className={`h-4 w-4 ${activeTab === 'retryError' ? 'text-primary' : 'text-gray-500'}`} />
              <div>
                <div className={`text-sm font-medium ${activeTab === 'retryError' ? 'text-primary' : 'text-gray-700'}`}>
                  Retry Error
                </div>
                <div className="text-[11px] text-gray-500">
                  Prompt for retry error handling
                </div>
              </div>
            </button>
          </div>
        </div>
  
        {/* Content Area */}
        <div className="flex-1">
          <Card className="h-full">
            <div className="h-full flex flex-col">
              {/* General Prompt Tab */}
              {activeTab === 'general' && (
                <>
                  <div className="px-4 pb-4 border-b">
                    <PromptVersionList
                      versions={versions.prompt}
                      currentVersion={configData.promptId}
                      onVersionSelect={(version) => handleVersionSelect('prompt', version)}
                      onVersionApply={(version) => handleVersionApply('prompt', version)}
                      isLoading={isLoading}
                    />
                  </div>
                  <div className="flex-1">
                    <TextEditor
                      title=""
                      initialContent={formatText(previewData.prompt)}
                      onSave={(content) => handleSave('prompt', content)}
                      onCancel={() => handleCancel('prompt')}
                      height="h-full"
                    />
                  </div>
                </>
              )}
  
              {/* Explanation Prompt Tab */}
              {activeTab === 'explanation' && (
                <>
                  <div className="px-4 pb-4 border-b">
                    <PromptVersionList
                      versions={versions.explanationPrompt}
                      currentVersion={configData.explanationPromptId}
                      onVersionSelect={(version) => handleVersionSelect('explanationPrompt', version)}
                      onVersionApply={(version) => handleVersionApply('explanationPrompt', version)}
                      isLoading={isLoading}
                    />
                  </div>
                  <div className="flex-1">
                    <TextEditor
                      title=""
                      initialContent={formatText(previewData.explanationPrompt)}
                      onSave={(content) => handleSave('explanationPrompt', content)}
                      onCancel={() => handleCancel('explanationPrompt')}
                      height="h-full"
                    />
                  </div>
                </>
              )}
  
              {/* Investigate Empty Tab */}
              {activeTab === 'investigateEmpty' && (
                <>
                  <div className="px-4 pb-4 border-b">
                    <PromptVersionList
                      versions={versions.investigateEmptyPrompt}
                      currentVersion={configData.investigateEmptyPromptId}
                      onVersionSelect={(version) => handleVersionSelect('investigateEmptyPrompt', version)}
                      onVersionApply={(version) => handleVersionApply('investigateEmptyPrompt', version)}
                      isLoading={isLoading}
                    />
                  </div>
                  <div className="flex-1">
                    <TextEditor
                      title=""
                      initialContent={formatText(previewData.investigateEmptyPrompt)}
                      onSave={(content) => handleSave('investigateEmptyPrompt', content)}
                      onCancel={() => handleCancel('investigateEmptyPrompt')}
                      height="h-full"
                    />
                  </div>
                </>
              )}
  
              {/* Retry Error Tab */}
              {activeTab === 'retryError' && (
                <>
                  <div className="px-4 pb-4 border-b">
                    <PromptVersionList
                      versions={versions.retryErrorPrompt}
                      currentVersion={configData.retryErrorPromptId}
                      onVersionSelect={(version) => handleVersionSelect('retryErrorPrompt', version)}
                      onVersionApply={(version) => handleVersionApply('retryErrorPrompt', version)}
                      isLoading={isLoading}
                    />
                  </div>
                  <div className="flex-1">
                    <TextEditor
                      title=""
                      initialContent={formatText(previewData.retryErrorPrompt)}
                      onSave={(content) => handleSave('retryErrorPrompt', content)}
                      onCancel={() => handleCancel('retryErrorPrompt')}
                      height="h-full"
                    />
                  </div>
                </>
              )}
            </div>
          </Card>
        </div>
      </div>
    </div>
  );
};

export default PromptManagement;