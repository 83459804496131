import React, { useState, useEffect } from 'react';
import Card from '../Card';
import { db } from '../../firebase';
import { doc, getDoc, getFirestore, updateDoc, collection, query, orderBy, getDocs, addDoc, serverTimestamp, setDoc, limit } from 'firebase/firestore';
import { Loader2, Tag, Plus, X } from 'lucide-react';
import VersionList from './VersionList';
import { useAuth } from '../../contexts/AuthContext';

const LabelsManagement = () => {
  const [configData, setConfigData] = useState({
    labels: [],
    labelId: ''
  });
  const [previewData, setPreviewData] = useState({
    labels: []
  });
  const [versions, setVersions] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [saveStatus, setSaveStatus] = useState({ type: '', message: '' });
  const [newLabel, setNewLabel] = useState('');
  const [labelCounts, setLabelCounts] = useState({});
  const { currentUser, isAdmin } = useAuth();

  // Fetch initial data, versions, and label counts
  useEffect(() => {
    const fetchData = async () => {
      if (!currentUser) return;
      
      try {
        setIsLoading(true);
        
        // Fetch label counts from docs collection
        const docsQuery = query(collection(db, 'docs'));
        const docsSnapshot = await getDocs(docsQuery);
        const counts = {};
        
        docsSnapshot.forEach(doc => {
          const data = doc.data();
          if (data.labels) {
            data.labels.forEach(label => {
              // Handle both string labels and label objects
              const labelName = typeof label === 'string' ? label : label.label;
              counts[labelName] = (counts[labelName] || 0) + 1;
            });
          }
        });
        
        setLabelCounts(counts);

        // Only admins can fetch and manage labels configuration
        if (isAdmin) {
          const configDoc = await getDoc(doc(db, 'DocsLabels', 'Config'));
          
          if (configDoc.exists()) {
            const configData = configDoc.data();
            
            const versionsQuery = query(
              collection(db, 'DocsLabels', 'Config', 'versions'),
              orderBy('versionNumber', 'desc'),
              limit(10)
            );
            const versionsSnapshot = await getDocs(versionsQuery);
            
            const labelVersions = versionsSnapshot.docs.map(doc => ({
              id: doc.id,
              ...doc.data()
            }));
          
            setVersions(labelVersions);
          
            const currentVersion = labelVersions.find(v => v.id === configData.currentVersion);
            
            // Define initialConfig before using it
            const initialConfig = {
              labels: currentVersion?.labels || [],
              labelId: configData.currentVersion
            };
          
            setConfigData(initialConfig);
            setPreviewData({
              labels: initialConfig.labels
            });
          } else {
            // Initialize if doesn't exist
            const initialVersion = {
              labels: [],
              versionNumber: 1,
              createdAt: serverTimestamp(),
              createdBy: currentUser?.email || 'unknown'
            };

            await setDoc(doc(db, 'DocsLabels', 'Config'), {
              currentVersion: null
            });

            const versionRef = await addDoc(
              collection(db, 'DocsLabels', 'Config', 'versions'),
              initialVersion
            );
            
            await updateDoc(doc(db, 'DocsLabels', 'Config'), {
              currentVersion: versionRef.id
            });

            setConfigData({
              labels: [],
              labelId: versionRef.id
            });
            setPreviewData({ labels: [] });
            setVersions([{ ...initialVersion, id: versionRef.id }]);
          }
        } else {
          // For non-admin users, just show a message that they don't have permission
          setError('You do not have permission to manage labels. Please contact an administrator.');
        }
      } catch (err) {
        setError(err.message);
        console.error('Error fetching labels config:', err);
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, [currentUser, isAdmin]);


  const handleVersionSelect = (version) => {
    setPreviewData({
      labels: version.labels
    });
  };

  const handleVersionApply = async (version) => {
    try {
      setSaveStatus({ type: 'loading', message: 'Applying version...' });
      
      // Update the version document
      const versionRef = doc(db, 'DocsLabels', 'Config', 'versions', version.id);
      await updateDoc(versionRef, {
        lastAppliedAt: serverTimestamp(),
        lastAppliedBy: currentUser?.email || 'unknown'
      });
  
      // Update the config
      await updateDoc(doc(db, 'DocsLabels', 'Config'), {
        currentVersion: version.id
      });
  
      // Update states
      setConfigData(prev => ({
        ...prev,
        labels: version.labels,
        labelId: version.id
      }));
      setPreviewData({
        labels: version.labels
      });
  
      // Update versions state
      setVersions(prev => prev.map(v => 
        v.id === version.id 
          ? { 
              ...v, 
              lastAppliedAt: new Date(),
              lastAppliedBy: currentUser?.email || 'unknown'
            } 
          : v
      ));
  
      setSaveStatus({ type: 'success', message: `Applied version ${version.versionNumber}` });
    } catch (error) {
      console.error('Error applying version:', error);
      setSaveStatus({ type: 'error', message: 'Failed to apply version' });
    } finally {
      setTimeout(() => setSaveStatus({ type: '', message: '' }), 3000);
    }
  };

  const handleAddLabel = async () => {
    if (!newLabel.trim()) return;
    
    try {
      setSaveStatus({ type: 'loading', message: 'Adding new label...' });
      
      const updatedLabels = [...previewData.labels, newLabel.trim()];
      await handleSaveLabels(updatedLabels);
      
      setNewLabel('');
    } catch (error) {
      console.error('Error adding label:', error);
      setSaveStatus({ type: 'error', message: 'Failed to add label' });
    }
  };

  const handleRemoveLabel = async (labelToRemove) => {
    try {
      setSaveStatus({ type: 'loading', message: 'Removing label...' });
      
      const updatedLabels = previewData.labels.filter(label => label !== labelToRemove);
      await handleSaveLabels(updatedLabels);
    } catch (error) {
      console.error('Error removing label:', error);
      setSaveStatus({ type: 'error', message: 'Failed to remove label' });
    }
  };

  const handleSaveLabels = async (updatedLabels) => {
    try {
      setSaveStatus({ type: 'loading', message: 'Saving new version...' });
      
      // Create new version
      const nextVersionNumber = Math.max(...versions.map(v => v.versionNumber || 0)) + 1;
      
      const newVersion = {
        labels: updatedLabels,
        versionNumber: nextVersionNumber,
        createdAt: serverTimestamp(),
        createdBy: currentUser?.email || 'unknown',
        lastAppliedAt: serverTimestamp(),
        lastAppliedBy: currentUser?.email || 'unknown'
      };

      const versionsRef = collection(db, 'DocsLabels', 'Config', 'versions');
      const newVersionRef = await addDoc(versionsRef, newVersion);

      // Update Config
      await updateDoc(doc(db, 'DocsLabels', 'Config'), {
        currentVersion: newVersionRef.id
      });

      // Update states
      const newVersionWithId = {
        id: newVersionRef.id,
        ...newVersion,
        createdAt: new Date(),
        lastAppliedAt: new Date()
      };

      setVersions(prev => [newVersionWithId, ...prev]);
      setConfigData(prev => ({
        ...prev,
        labels: updatedLabels,
        labelId: newVersionRef.id
      }));
      setPreviewData({
        labels: updatedLabels
      });

      setSaveStatus({ type: 'success', message: 'Labels updated successfully!' });
    } catch (error) {
      console.error('Error saving labels:', error);
      setSaveStatus({ type: 'error', message: 'Failed to save labels' });
    } finally {
      setTimeout(() => setSaveStatus({ type: '', message: '' }), 3000);
    }
  };

  if (isLoading) {
    return (
      <div className="flex items-center justify-center min-h-screen">
        <Loader2 className="h-8 w-8 animate-spin text-gray-500" />
      </div>
    );
  }

  if (error) {
    return (
      <div className="p-4 text-red-500">
        Error loading labels: {error}
      </div>
    );
  }

  return (
    <div className="w-full space-y-4">
      {/* Status Messages */}
      {saveStatus.message && (
        <div className={`px-4 py-2 rounded ${
          saveStatus.type === 'success' ? 'bg-green-100 text-green-700' :
          saveStatus.type === 'error' ? 'bg-red-100 text-red-700' :
          'bg-blue-100 text-blue-700'
        }`}>
          {saveStatus.message}
        </div>
      )}

      <Card>
        <div className="px-4 pb-4 border-b">
          <VersionList
            versions={versions}
            currentVersion={configData.labelId}
            onVersionSelect={handleVersionSelect}
            onVersionApply={handleVersionApply}
            isLoading={isLoading}
            type="labels"
          />
        </div>

        <div className="p-4">
          <div className="mb-6">
            <div className="flex items-center gap-2 mb-4">
              <input
                type="text"
                value={newLabel}
                onChange={(e) => setNewLabel(e.target.value)}
                placeholder="Enter new label"
                className="flex-1 px-3 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                onKeyPress={(e) => e.key === 'Enter' && handleAddLabel()}
              />
              <button
                onClick={handleAddLabel}
                className="px-4 py-2 bg-primary text-white rounded-md hover:bg-opacity-90 transition-colors flex items-center gap-2"
                disabled={saveStatus.type === 'loading'}
              >
                {saveStatus.type === 'loading' ? (
                  <Loader2 className="h-4 w-4 animate-spin" />
                ) : (
                  <Plus className="h-4 w-4" />
                )}
                Add Label
              </button>
            </div>
          </div>

          <div className="flex flex-wrap gap-2">
            {previewData.labels.map((label, index) => (
              <div
                key={index}
                className="flex items-center gap-2 px-3 py-1.5 bg-gray-100 rounded-full group hover:bg-gray-200 transition-colors"
              >
                <Tag className="h-4 w-4 text-gray-500" />
                <span>{label}</span>
                {labelCounts[label] > 0 && (
                  <span className="text-xs text-gray-500 bg-white px-2 py-0.5 rounded-full">
                    {labelCounts[label]}
                  </span>
                )}
                <button
                  onClick={() => handleRemoveLabel(label)}
                  className="opacity-0 group-hover:opacity-100 transition-opacity"
                  disabled={saveStatus.type === 'loading'}
                >
                  <X className="h-4 w-4 text-gray-500 hover:text-red-500" />
                </button>
              </div>
            ))}
            {previewData.labels.length === 0 && (
              <div className="text-gray-500 italic">No labels defined</div>
            )}
          </div>
        </div>
      </Card>
    </div>
  );
};

export default LabelsManagement;