// src/components/Docs/DocsManagement.js
import React, { useState } from 'react';
import UploadSection from './UploadSection';
import DocumentList from './DocumentList';
import StatusMessage from './StatusMessage';
import LabelsManagement from './LabelsManagement';
import CategoriesManagement from './CategoriesManagement';
import LabelGeneration from './LabelGeneration';
import AutoLabeling from './AutoLabeling';
import AutoCategorize from './AutoCategorize';
import DocsConfigManagement from './DocsConfigManagement';
import { useDocuments } from '../../hooks/useDocuments';
import { Tag, FileText, Zap, Settings, FolderTree, Tags, Database } from 'lucide-react';

const DocsManagement = () => {
  const {
    documents,
    isUploading,
    error,
    saveStatus,
    handleUpload,
    updateCategories,
    batchDeleteDocuments,
  } = useDocuments();

  const [activeTab, setActiveTab] = useState('documents');
  const [autoLabelResults, setAutoLabelResults] = useState(null);
  const [batchProgress, setBatchProgress] = useState(null);
  const [isProcessing, setIsProcessing] = useState(false);
  const [labelError, setLabelError] = useState(null);
  
  // New states for auto-categorize
  const [autoCategorizeResults, setAutoCategorizeResults] = useState(null);
  const [categorizeBatchProgress, setCategorizeBatchProgress] = useState(null);
  const [isCategorizingProcessing, setIsCategorizingProcessing] = useState(false);
  const [categorizeError, setCategorizeError] = useState(null);
  
  const [suggestedLabels, setSuggestedLabels] = useState(() => {
    const savedLabels = localStorage.getItem('suggestedLabels');
    return savedLabels ? JSON.parse(savedLabels) : [];
  });

  return (
    <div className="space-y-4">
      {/* Tab Navigation */}
      <div className="border-b border-gray-200">
        <div className="flex items-end gap-6">
          <button
            onClick={() => setActiveTab('documents')}
            className={`relative flex items-center gap-2 px-4 py-3 text-sm font-medium transition-all ${
              activeTab === 'documents' ? 'text-primary' : 'text-gray-600 hover:text-gray-900'
            }`}
          >
            <FileText className="h-4 w-4" />
            Documents
            {activeTab === 'documents' && (
              <div className="absolute bottom-0 left-0 right-0 h-0.5 bg-primary" />
            )}
          </button>

          <div className="flex gap-px bg-gray-50 rounded-t-md">
            <button
              onClick={() => setActiveTab('categories')}
              className={`relative flex items-center gap-2 px-4 py-3 text-sm font-medium transition-all ${
                activeTab === 'categories' ? 'text-primary' : 'text-gray-600 hover:text-gray-900'
              }`}
            >
              <FolderTree className="h-4 w-4" />
              Categories
              {activeTab === 'categories' && (
                <div className="absolute bottom-0 left-0 right-0 h-0.5 bg-primary" />
              )}
            </button>
            
            <button
              onClick={() => setActiveTab('auto-categorize')}
              className={`relative flex items-center gap-2 px-4 py-3 text-sm font-medium transition-all ${
                activeTab === 'auto-categorize' ? 'text-primary' : 'text-gray-600 hover:text-gray-900'
              }`}
            >
              <Database className="h-4 w-4" />
              Auto Categorize
              {activeTab === 'auto-categorize' && (
                <div className="absolute bottom-0 left-0 right-0 h-0.5 bg-primary" />
              )}
            </button>
          </div>

          <div className="flex gap-px bg-gray-50 rounded-t-md">
            <button
              onClick={() => setActiveTab('labels')}
              className={`relative flex items-center gap-2 px-4 py-3 text-sm font-medium transition-all ${
                activeTab === 'labels' ? 'text-primary' : 'text-gray-600 hover:text-gray-900'
              }`}
            >
              <Tag className="h-4 w-4" />
              Labels
              {activeTab === 'labels' && (
                <div className="absolute bottom-0 left-0 right-0 h-0.5 bg-primary" />
              )}
            </button>
            
            <button
              onClick={() => setActiveTab('labelgeneration')}
              className={`relative flex items-center gap-2 px-4 py-3 text-sm font-medium transition-all ${
                activeTab === 'labelgeneration' ? 'text-primary' : 'text-gray-600 hover:text-gray-900'
              }`}
            >
              <Tags className="h-4 w-4" />
              Label Generation
              {activeTab === 'labelgeneration' && (
                <div className="absolute bottom-0 left-0 right-0 h-0.5 bg-primary" />
              )}
            </button>
            
            <button
              onClick={() => setActiveTab('auto-label')}
              className={`relative flex items-center gap-2 px-4 py-3 text-sm font-medium transition-all ${
                activeTab === 'auto-label' ? 'text-primary' : 'text-gray-600 hover:text-gray-900'
              }`}
            >
              <Zap className="h-4 w-4" />
              Auto Label
              {activeTab === 'auto-label' && (
                <div className="absolute bottom-0 left-0 right-0 h-0.5 bg-primary" />
              )}
            </button>
          </div>

          <button
            onClick={() => setActiveTab('config')}
            className={`relative flex items-center gap-2 px-4 py-3 text-sm font-medium transition-all ${
              activeTab === 'config' ? 'text-primary' : 'text-gray-600 hover:text-gray-900'
            }`}
          >
            <Settings className="h-4 w-4" />
            Config
            {activeTab === 'config' && (
              <div className="absolute bottom-0 left-0 right-0 h-0.5 bg-primary" />
            )}
          </button>
        </div>
      </div>

      {/* Content Area */}
      {activeTab === 'documents' && (
        <>
          <UploadSection 
            onUpload={handleUpload}
            isUploading={isUploading}
          />
          
          {error && (
            <StatusMessage
              type="error"
              message={error}
            />
          )}

          {(isUploading || saveStatus) && (
            <StatusMessage
              type={saveStatus?.type}
              message={saveStatus?.message}
              isLoading={isUploading}
            />
          )}

          <DocumentList 
            documents={documents} 
            updateCategories={updateCategories}
            batchDeleteDocuments={batchDeleteDocuments}
          />
        </>
      )}
      
      {activeTab === 'labels' && <LabelsManagement />}
      {activeTab === 'labelgeneration' && 
        <LabelGeneration 
          suggestedLabels={suggestedLabels}
          setSuggestedLabels={setSuggestedLabels}
        />
      }
      {activeTab === 'categories' && <CategoriesManagement />}
      {activeTab === 'auto-label' && (
        <AutoLabeling 
          results={autoLabelResults}
          setResults={setAutoLabelResults}
          batchProgress={batchProgress}
          setBatchProgress={setBatchProgress}
          isProcessing={isProcessing}
          setIsProcessing={setIsProcessing}
          error={labelError}
          setError={setLabelError}
        />
      )}
      {activeTab === 'auto-categorize' && (
        <AutoCategorize 
          results={autoCategorizeResults}
          setResults={setAutoCategorizeResults}
          batchProgress={categorizeBatchProgress}
          setBatchProgress={setCategorizeBatchProgress}
          isProcessing={isCategorizingProcessing}
          setIsProcessing={setIsCategorizingProcessing}
          error={categorizeError}
          setError={setCategorizeError}
        />
      )}
      {activeTab === 'config' && <DocsConfigManagement />}
    </div>
  );
};

export default DocsManagement;