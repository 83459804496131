import React, { useState, useEffect } from 'react';
import { Copy, Code, FileText, FilePlus2, Info, MailQuestion, Download, Share2 } from 'lucide-react';
import Button from '../Button';
import PopupCard from '../PopupCard';
import SyntaxHighlighter from 'react-syntax-highlighter';
import { docco } from 'react-syntax-highlighter/dist/esm/styles/hljs';
import { useAuth } from '../../contexts/AuthContext';
import { getFirestore, doc, getDoc } from 'firebase/firestore';
import { format } from 'date-fns';
import ShareQueryResult from './ShareQueryResult';
import * as XLSX from 'xlsx';
import { createImageWrapper, captureImage } from './imageUtils';

const ActionPanel = ({ 
  result, 
  onSaveQuery, 
  isSavedQuery, 
  onExplanationClick, 
  isExplanationLoading, 
  isExplanationPending,
  explanation, 
  isDashboardMode, 
  isEmptyResult,
  isInDashboard,
  viewMode,
  chartRef,
  cardTitle,
  statsData,
  selectedYAxis,
  truncationMessage
}) => {
  const [supportEmails, setSupportEmails] = useState([]);
  const [showSqlPopup, setShowSqlPopup] = useState(false);
  const [showLogsPopup, setShowLogsPopup] = useState(false);
  const [showLogicPopup, setShowLogicPopup] = useState(false);
  const [copiedResult, setCopiedResult] = useState(false);
  const [copiedSql, setCopiedSql] = useState(false);
  const { isAdmin } = useAuth();  // Restored this hook

  useEffect(() => {
    const fetchSupportEmails = async () => {
      const db = getFirestore();
      const configDoc = await getDoc(doc(db, 'DBI Configuration', 'Config'));
      if (configDoc.exists()) {
        setSupportEmails(configDoc.data().Support || []);
      }
    };
    fetchSupportEmails();
  }, []);

  const copyToClipboard = (text, setCopiedState) => {
    navigator.clipboard.writeText(text).then(() => {
      setCopiedState(true);
      setTimeout(() => setCopiedState(false), 2000);
    }, (err) => {
      console.error('Failed to copy: ', err);
    });
  };

  const formatResultForCopy = (data) => {
    if (!Array.isArray(data)) {
      return JSON.stringify(data, null, 2);
    }
    return JSON.stringify(data.map(item => {
      if (typeof item !== 'object' || item === null) {
        return item;
      }
      return Object.fromEntries(
        Object.entries(item).map(([key, value]) => {
          if (value && typeof value === 'object' && 'displayValue' in value) {
            return [key, value.displayValue];
          }
          return [key, value];
        })
      );
    }), null, 2);
  };

  const formatLogs = () => {
    return `
User Query:
${result.userQuery}

Configuration Details:
${JSON.stringify(result.config, null, 2)}

SQL Generated by LLM:
${result.sqlQuery}

Response from BigQuery:
${formatResultForCopy(result.result)}

Document ID:
${result.documentId}
    `;
  };

  const handleReportIssue = () => {
    const subject = encodeURIComponent(`Support ticket about a query from ${window.location.hostname}, ${format(new Date(), 'dd/MM/yy HH:mm')}`);
    const body = encodeURIComponent(`
User Query:
${result.userQuery}

Document ID:
${result.documentId}
    `);
    const mailtoLink = `mailto:${supportEmails.join(',')}?subject=${subject}&body=${body}`;
    window.open(mailtoLink, '_blank');
  };


  const handleDownload = async () => {
    if (truncationMessage) return; // Prevent download if data is truncated
    if (viewMode !== 'table') {
      try {
      const wrapper = createImageWrapper(chartRef, cardTitle, statsData, selectedYAxis);
      const canvas = await captureImage(wrapper);
      
      canvas.toBlob((blob) => {
        const url = URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.download = `${cardTitle || 'chart'}_${format(new Date(), 'yyyyMMdd_HHmmss')}.png`;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        URL.revokeObjectURL(url);
      }, 'image/png');
    } catch (error) {
      console.error('Error downloading chart:', error);
      alert('Failed to download chart. Please try again.');
    }
  } else {
    try {
      // Flatten the data structure before creating Excel file
      const flattenedData = result.result.map(row => {
        const flatRow = {};
        Object.entries(row).forEach(([key, value]) => {
          // Check if the value is an object with a 'value' property (like date fields)
          if (value && typeof value === 'object' && 'value' in value) {
            flatRow[key] = value.value;
          } else if (value && typeof value === 'object' && 'displayValue' in value) {
            // Handle cases where the value might have a displayValue property
            flatRow[key] = value.displayValue;
          } else {
            flatRow[key] = value;
          }
        });
        return flatRow;
      });

      const workbook = XLSX.utils.book_new();
      const worksheet = XLSX.utils.json_to_sheet(flattenedData);
      XLSX.utils.book_append_sheet(workbook, worksheet, "Data");
      XLSX.writeFile(workbook, `${cardTitle || 'data'}_${format(new Date(), 'yyyyMMdd_HHmmss')}.xlsx`);
    } catch (error) {
      console.error('Error downloading table:', error);
      alert('Failed to download table. Please try again.');
    }
  }
};

  return (
    <div className="flex justify-end space-x-2 font-sans">
      <ShareQueryResult 
        viewMode={viewMode}
        chartRef={chartRef}
        tableData={result.result}
        fileName={`${cardTitle || 'query_result'}_${format(new Date(), 'yyyyMMdd_HHmmss')}`}
        cardTitle={cardTitle}
        statsData={statsData}
        selectedYAxis={selectedYAxis}
      />
      <Button
        onClick={handleDownload}
        icon={Download}
        className={`p-2 ${truncationMessage ? 'opacity-50 cursor-not-allowed' : ''}`}
        title={truncationMessage ? "Download is disabled for large data sets" : "Download Result"}
        disabled={!!truncationMessage}
      />

      {isInDashboard && (
        <Button
          onClick={() => {
            setShowLogicPopup(true);
            if (!explanation && !isExplanationPending) {
              onExplanationClick();
            }
          }}
          icon={Info}
          className="p-2"
          title="Show Logic"
        />
      )}
  
      {isAdmin && (
        <Button
          onClick={() => copyToClipboard(formatResultForCopy(result.result), setCopiedResult)}
          icon={Copy}
          className="p-2"
          title={copiedResult ? 'Copied!' : 'Copy Result'}
        />
      )}
  
      {isAdmin && (
        <Button
          onClick={() => setShowLogsPopup(true)}
          icon={FileText}
          className="p-2"
          title="View Logs"
        />
      )}
  
      {isAdmin && (
        <Button
          onClick={() => setShowSqlPopup(true)}
          icon={Code}
          className="p-2"
          title="View SQL"
        />
      )}
  
      {!isDashboardMode && !isSavedQuery && (
        <Button
          onClick={onSaveQuery}
          icon={FilePlus2}
          className="p-2"
          title="Save to Dashboard"
        />
      )}
  
      <Button
        onClick={handleReportIssue}
        icon={MailQuestion}
        className="p-2"
        title="Report Issue"
      />
  
      <PopupCard
        isOpen={showSqlPopup}
        onClose={() => setShowSqlPopup(false)}
        title="SQL Query"
        footer={
          <Button
            onClick={() => copyToClipboard(result.sqlQuery, setCopiedSql)}
            icon={Copy}
            className="p-2"
            title={copiedSql ? 'Copied!' : 'Copy SQL'}
          />
        }
        className="z-50 font-sans"
        contentClassName="w-full max-w-2xl"
      >
        <div className="max-h-[60vh] overflow-auto">
          <SyntaxHighlighter language="sql" style={docco} customStyle={{ fontSize: '0.875rem' }}>
            {result?.sqlQuery}
          </SyntaxHighlighter>
        </div>
      </PopupCard>
  
      <PopupCard
        isOpen={showLogsPopup}
        onClose={() => setShowLogsPopup(false)}
        title="Debug Logs"
        className="z-50 font-sans"
        contentClassName="w-full max-w-2xl"
      >
        <div className="mt-4 p-4 bg-gray-50 rounded-md max-h-[60vh] overflow-y-auto">
          <pre className="whitespace-pre-wrap break-words text-sm">
            {formatLogs()}
          </pre>
        </div>
      </PopupCard>
  
      <PopupCard
        isOpen={showLogicPopup}
        onClose={() => setShowLogicPopup(false)}
        title={isEmptyResult ? "Here's How I've Tried Finding the Data" : "Behind the Query"}
        footer={null}
        className="z-50 font-sans"
        contentClassName="w-full max-w-2xl"
      >
        <div className="max-h-[60vh] overflow-auto">
          {isExplanationLoading || isExplanationPending ? (
            <div className="flex justify-center items-center h-full">
              <div className="animate-spin border-4 border-t-4 border-primary rounded-full w-8 h-8"></div>
            </div>
          ) : explanation ? (
            <div className="whitespace-pre-wrap break-words text-sm font-sans">
              {explanation}
            </div>
          ) : (
            <p className="text-sm text-gray-600 font-sans">
              Explanation not available. Click the "Show Logic" button to generate an explanation.
            </p>
          )}
        </div>
      </PopupCard>
    </div>
  );
};

export default ActionPanel;